(function () {
  'use strict';

  /* @ngdoc object
   * @name home.matches
   * @description
   *
   */
  angular
    .module('neo.public.classificacio', [
      'ui.router'
    ]);
}());

